.community-editor-container{
  @import './_communityBasicsEditor.scss';

  .listing-application-form{
    .listing-application-instructions-row {
      .ide-document-form-label {
        padding-top: 8px;
        width: 150px;
      }

      .ide-document-form-column {
        vertical-align: top;
      }
    }

    .url-validation {
      color: $danger-color;
    }

    input {
      width: 340px;
    }

    textarea{
      width: 340px;
      height: 100px;
      border: none;
      resize: none;
      margin-top: 8px;
    }
  }

  .community-leasing-form{
    textarea{
      width: 340px;
      height: 100px;
      border: none;
      resize: none;
      margin-top: 8px;
    }

    .community-lease-lengths-row{
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .ide-document-form-column{
      width: 330px;

      input{
        width: 330px;
      }

      .dollar-sign{
        position: relative;
        left: 25px;
      }

      &.community-monthly-rent{
        position: relative;
        left: -15px;

        input {
          width: 200px;
          padding-left: 30px;
        }

      }

      &.ide-document-form-label{
        width: 150px;
      }
    }

    .community-sublet-row{
      padding-top: 8px;
    }
  }

  .photos-alert {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
    margin: 20px;
    text-align: center;
    width: 720px;

      p {
        margin: 0
      }
  }

  &.inline-document-editor {

    .ide-document.readonly .ide-document-section-content * {
      background-color: #f8f8f8;
    }
  }
}

.offset-community-new-sidenav {
  left: 240px !important;
}